import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import axios from "axios";

function App() {
  const [specials, setSpecials] = useState([]);

  const getSpecials = async () => {
    const dataArray: any = await axios.get(
      `https://api.earthgiftcannabis.com/v1/specials/find`
    );
    const data = dataArray.data[0][0];
    setSpecials(data.items);
    console.log(data.items);
  };

  useEffect(() => {
    //https://api.earthgiftcannabis.com/v1/specials/find
    getSpecials();

    return () => {};
  }, []);

  return (
    <div className="App">
      <div className="container text-center mg-t-20">
        <img src={logo} alt="logo" className="logo" />
        <h3 className="greenTitle">Especiales</h3>
        <h4 className="text-center instructionsText">
          Ahora con servicio de <strong>“PAGERS”...</strong> Espere en su auto.
        </h4>
        <p className="instructionsText">
          <strong>La GOTITA</strong> se preocupa por tu salud y como método
          preventivo ante el COVID-19 te recomendamos realizar tu orden
          llamándonos al 787-800-4000 (Carolina) y 787-436-3638 (Trujillo Alto).
        </p>
               <b>LUNES A SÁBADO / 9:00 AM A 9:00 PM</b>
          <br />
          <b>DOMINGO / 12:00 PM A 5:00 PM</b>
        </p>
        <p>
          Pregunta por los productos con LA GOTITA, hechos con flores de nuestro
          cultivo artesanal.
        </p>
        <ul className="ul-div">
          <li className="li-style">
            {specials.map(function (d: any, idx) {
              return (
                <li key={idx}>
                  <b className="li-title">{d.title}</b>
                  <p>
                    <div
                      className="specials-info"
                      dangerouslySetInnerHTML={{
                        __html: d.info,
                      }}
                    ></div>
                  </p>
                </li>
              );
            })}
          </li>
        </ul>

        <small>
          *Disponibilidad puede variar por dispensario. No aplican otros
          descuentos. Hasta que duren existencias.
        </small>
        <h3 className="greenTitle">Tiendas</h3>
      </div>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex flex-column align-items-center align-items-sm-center align-items-md-center justify-content-lg-center align-items-lg-center justify-content-xl-center align-items-xl-center">
              <h1 className="tiendasLocationTitle">Carolina</h1>
              <small>787-800-4000</small>
              <p className="text-center d-md-flex">
                Laguna Gardens Shopping Center, Av. Laguna, Carolina, 00979
              </p>
              <div>
                <a href="tel:787-800-4000">
                  <i className="fa fa-phone phoneIcon"></i>
                </a>
                <a
                  href="https://www.google.com/maps/dir/?api=1&destination=Avenida%20Laguna%20%2310%20Local%20103%20Laguna%20Garden%20Shopping%20Center%20Carolina%2C%20Puerto%20Rico%20US%2000985"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-location-arrow phoneIcon"></i>
                </a>
              </div>
            </div>
            <div className="col-md-6 d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex flex-column align-items-center align-items-sm-center align-items-md-center align-items-lg-center justify-content-xl-center align-items-xl-center">
              <h1 className="tiendasLocationTitle">Trujillo Alto</h1>
              <small>787-436-3638</small>
              <p className="text-center">
                Plaza San Miguel,&nbsp;Trujillo Alto, 00976
              </p>
              <div>
                <a href="tel:787-436-3638">
                  <i className="fa fa-phone phoneIcon"></i>
                </a>
                <a
                  href="https://www.google.com/maps/place/PLAZA+SAN+MIGUEL/@18.3662616,-66.0788931,12z/data=!4m16!1m10!4m9!1m1!4e2!1m6!1m2!1s0x8c0367984948b327:0x3a34ee363470d299!2sPLAZA+SAN+MIGUEL,+Unnamed+Road,+Trujillo+Alto,+00976!2m2!1d-66.0088526!2d18.3662744!3m4!1s0x8c0367984948b327:0x3a34ee363470d299!8m2!3d18.3662744!4d-66.0088526"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-location-arrow phoneIcon"></i>
                </a>
              </div>
            </div>
          </div>
          <h3 className="tiendasTitle">Siguenos en:</h3>
        </div>
      </div>
      <div className="social-icons">
        <a
          href="https://twitter.com/earth_gift_?lang=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="icon ion-social-twitter"></i>
        </a>

        <a
          href="https://www.instagram.com/earthgift/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="icon ion-social-instagram"></i>
        </a>
      </div>
      <footer className="copyright">
        <div className="content">EarthGift by CreativExit © 2020</div>
      </footer>
      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/bootstrap/js/bootstrap.min.js"></script>
    </div>
  );
}

export default App;
